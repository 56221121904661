const form_shipment = [
  {
    name: 'name',
    type: 'text',
    // placeholder: "*Nombre",
    required: true,
    errorMessage: 'Por favor completa este campo',
    validation: false,
    // icon: <FaUser />,
  },
  {
    name: 'email',
    type: 'email',
    // placeholder: "*Email",
    required: true,
    errorMessage: 'Por favor completa este campo',
    validation: /^\S+@\S+$/i,
    // icon: <FaEnvelope />,
  },
  {
    name: 'tel',
    type: 'tel',
    // placeholder: "*Teléfono / celular",
    required: true,
    errorMessage: 'Por favor completa este campo',
    validation: /^[0-9]+$/i,
    // validation: /^[0-9]+$/i,
    // icon: <FaEnvelope />,
  },
  {
    name: 'origin',
    type: 'text',
    placeholder: '*Origen del envío',
    required: true,
    errorMessage: 'Por favor completa este campo',
    validation: false,
    // icon: <FaMapMarkerAlt />,
  },
  {
    name: 'destiny',
    type: 'text',
    // placeholder: "*Destino del envío",
    required: true,
    errorMessage: 'Por favor completa este campo',
    validation: false,
    // icon: <FaMapMarkerAlt />,
  },
  {
    name: 'locality',
    type: 'text',
    // placeholder: "*Localidad",
    required: true,
    errorMessage: 'Por favor completa este campo',
    validation: /^\S+@\S+$/i,
    // icon: <FaMapMarkedAlt />,
  },
  {
    name: 'company',
    type: 'text',
    // placeholder: "*Empresa",
    required: true,
    errorMessage: 'Por favor completa este campo',
    validation: /^\S+@\S+$/i,
    // icon: <IoBusinessSharp />,
  },
  {
    name: 'qtyShipment',
    type: 'select',
    options: [
      {
        value: 'De 1 a 30 envíos',
        name: 'De 1 a 30 envíos',
      },
      {
        value: 'De 31 a 50 envíos',
        name: 'De 31 a 50 envíos',
      },
      {
        value: 'De 51 a 100 envíos',
        name: 'De 51 a 100 envíos',
      },
      {
        value: 'Más de 100 envíos',
        name: 'Más de 100 envíos',
      },
    ],
    placeholder: '*Cantidad Envíos Semanales:',
    required: true,
    errorMessage: 'Por favor completa este campo para cotizar',
    validation: /^\S+@\S+$/i,
    // icon: <FaShippingFast />,
  },
  {
    name: 'weight',
    type: 'select',
    options: [
      {
        value: 'Hasta 1kg',
        name: 'Hasta 1kg',
      },
      {
        value: 'Desde 1kg hasta 5kg',
        name: 'Desde 1kg hasta 5kg',
      },
      {
        value: 'Desde 5kg hasta 10kg',
        name: 'Desde 5kg hasta 10kg',
      },
      {
        value: 'Más de 10kg',
        name: 'Más de 10kg',
      },
    ],
    placeholder: '*Peso promedio por ',
    required: true,
    errorMessage: 'Por favor completa este campo para cotizar',
    validation: /^\S+@\S+$/i,
    // icon: <FaWeight />,
  },

  {
    name: 'service',
    type: 'select',
    options: [
      {
        value: 'encomiendas',
        name: 'Paquetería/Encomienda',
      },
      {
        value: 'paletizada',
        name: 'Venta paletizada',
      },
      {
        value: 'cargas',
        name: 'Cargas completas',
      },
      {
        value: 'e-comerce',
        name: 'Logística para E-Commerce',
      },
      {
        value: 'mudanza',
        name: 'Mudanza',
      },
      // {
      //   value: 'Recepcion',
      //   name: 'recepcion',
      // },
      {
        value: 'otros',
        name: 'Otros',
      },
    ],
    placeholder: 'Mudanza',
    required: true,
    errorMessage: 'Por favor completa este campo para cotizar',
    validation: /^\S+@\S+$/i,
    // icon: <FaWeight />,
  },
  {
    name: 'pago',
    type: 'select',
    options: [
      {
        value: 'efectivo',
        name: 'Efectivo',
      },
      {
        value: 'transferencia',
        name: 'Transferencia',
      },
    ],
    placeholder: 'Efectivo',
    required: true,
    errorMessage: 'Por favor completa este campo para cotizar',
    validation: /^\S+@\S+$/i,
    // icon: <FaWeight />,
  },
  {
    name: 'seguro',
    type: 'select',
    options: [
      {
        value: 'si',
        name: 'Si',
      },
      {
        value: 'no',
        name: 'No',
      },
    ],
    placeholder: 'Si',
    required: true,
    errorMessage: 'Por favor completa este campo para cotizar',
    validation: /^\S+@\S+$/i,
    // icon: <FaWeight />,
  },
  {
    name: 'rango',
    type: 'select',
    options: [
      {
        value: '9 a 11',
        name: '9 a 11',
      },
      {
        value: '12 a 16',
        name: '12 a 16',
      },
      {
        value: '16 a 18',
        name: '16 a 18',
      },
      {
        value: 'Todo el dia',
        name: 'Todo el dia',
      },
    ],
    placeholder: 'Todo el dia',
    required: true,
    errorMessage: 'Por favor completa este campo para generar tu retiro',
    validation: /^\S+@\S+$/i,
    // icon: <FaWeight />,
  },
  {
    name: 'nombreDestinatario',
    type: 'text',
    // placeholder: "*Nombre",
    required: true,
    errorMessage: 'Por favor completa este campo',
    validation: false,
    // icon: <FaUser />,
  },
  {
    name: 'telefonoDestinatario',
    type: 'text',
    // placeholder: "*Nombre",
    required: true,
    errorMessage: 'Por favor completa este campo',
    validation: false,
    // icon: <FaUser />,
  },
  {
    name: 'cantBultos',
    type: 'cantBultos',
    // placeholder: "*Teléfono / celular",
    required: true,
    errorMessage: 'Por favor completa este campo',
    validation: /^[0-9]+$/i,
    // icon: <FaEnvelope />,
  },
  {
    name: 'peso',
    type: 'peso',
    // placeholder: "*Teléfono / celular",
    required: true,
    errorMessage: 'Por favor completa este campo',
    validation: /^[0-9]+$/i,
    // icon: <FaEnvelope />,
  },
  {
    name: 'medidas',
    type: 'medidas',
    // placeholder: "*Teléfono / celular",
    required: true,
    errorMessage: 'Por favor completa este campo',
    validation: /^[0-9]+$/i,
    // icon: <FaEnvelope />,
  },
  {
    name: 'valor',
    type: 'valor',
    // placeholder: "*Teléfono / celular",
    required: true,
    errorMessage: 'Por favor completa este campo',
    validation: /^[0-9]+$/i,
    // icon: <FaEnvelope />,
  },
  {
    name: 'razonSocial',
    type: 'razonSocial',
    // placeholder: "*Teléfono / celular",
    required: true,
    errorMessage: 'Por favor completa este campo',
    // icon: <FaEnvelope />,
  },
  {
    name: 'cuit',
    type: 'cuit',
    // placeholder: "*Teléfono / celular",
    required: true,
    errorMessage: 'Por favor completa este campo',
    validation: /^[0-9]+$/i,
    // icon: <FaEnvelope />,
  },
  {
    name: 'asunto',
    type: 'select',
    options: [
      {
        value: 'Consulta',
        name: 'Consulta',
      },
      {
        value: 'Sugerencia y/o reclamos',
        name: 'Sugerencia y/o reclamos',
      },
      {
        value: 'Otro',
        name: 'Otro',
      },
    ],
    placeholder: 'Consulta',
    required: true,
    errorMessage: 'Por favor completa este campo para enviar tu consulta',
    validation: /^\S+@\S+$/i,
  },
  {
    name: 'message',
    type: 'text',
    // placeholder: "*Mensaje",
    required: true,
    errorMessage: 'Por favor completa este campo para cotizar',
    validation: false,
    // icon: <FaCommentDots />,
  },
]
export { form_shipment }
