export const sucList=[
    {
        city: 'Buenos Aires',
        address: 'Pergamino 3751',
    },
    {
        city: 'Gral. Alvear',
        address: 'José Ingenieros 162',
    },
    {
        city: 'Malargüe, Mendoza',
        address: 'Fortín, Malargüe Este 562',
    },
    {
        city: 'San Rafael',
        address: 'Av. del Libertador 444',
    },
    {
        city: 'Mendoza, Capital',
        address: 'Rodriguez Peña 4818',
    },
    {
        city: 'Realicó',
        address: 'Ruta Nacional 188 y Ruta Nacional 35',
    },
    {
        city: 'Tunuyán',
        address: 'San Martin 584, Valle de Uco',
    },
    {
        city: 'San Luis',
        address: 'Calle 101, Parque Industrial N. San Luis Capital',
    },
    {
        city: 'Rosario',
        address: 'José M Rosa 3846 - Altura Circunvalación 3846(Colectora Oeste)',
    },
    
]